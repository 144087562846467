export const GET_LEADS = 'GET_LEADS';
export const SET_LEADS = 'SET_LEADS';
export const LEADS_ERROR = 'LEADS_ERROR';
export const INIT = { items: [], count: 0 };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case GET_LEADS:
        case SET_LEADS:
            return payload;
        case LEADS_ERROR:
        default:
            return state;
    }
}

export default reducer;
