import React, { useState } from 'react';
import clsx from 'clsx';
// images
import { Box, Hidden, IconButton, Typography } from '@mui/material';
import { ExitToApp, Menu } from '@mui/icons-material';
// Styles
import Logo from 'assets/images/logos/logo-long-dark.png';
import useStyles from './styles';
import MobileDrawer from './Drawer/Drawer';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserRedux from 'redux/actions/user';
import Sidebar from './Sidebar/Sidebar';
import ReferredAlert from 'components/Alerts/ReferredAlert/ReferredAlert';
import DocusignAlert from 'components/Alerts/DocusignAlert/DocusignAlert';

export default function Authbar({ children }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    React.useEffect(() => {
        setDrawerOpen(false);
    }, [pathname]);

    async function handleLogout() {
        setDrawerOpen(false);
        await dispatch(UserRedux.signOut());
    }

    return (
        <Box className={classes.root}>
            <MobileDrawer open={drawerOpen} onLogout={handleLogout} onClose={() => setDrawerOpen(false)} />
            <Box className={clsx(classes.navigation, classes.authNav)}>
                <Link to="/">
                    <img src={Logo} className={classes.logo} />
                </Link>
                <Hidden smDown>
                    <Box className={classes.center}>
                        {user.ReferralPartner.masterCode && (
                            <Typography variant="font3" fontWeight={'400'}>
                                Network: {user.ReferralPartner.masterCode}
                            </Typography>
                        )}
                        <Box
                            className={clsx(classes.status, {
                                [classes.statusInactive]:
                                    !user.ReferralPartner.masterCode && user?.ReferralPartner?.docusign?.agreement?.status !== 'completed',
                            })}
                            sx={{ marginLeft: 1 }}
                        >
                            <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                {user.ReferralPartner.masterCode || user?.ReferralPartner?.docusign?.agreement?.status === 'completed'
                                    ? 'Active'
                                    : 'Docusign required'}
                            </Typography>
                        </Box>
                        <IconButton sx={{ marginLeft: 1 }} onClick={handleLogout}>
                            <ExitToApp />
                        </IconButton>
                    </Box>
                </Hidden>
                <Hidden smUp>
                    <IconButton onClick={() => setDrawerOpen(true)}>
                        <Menu sx={{ color: 'black' }} />
                    </IconButton>
                </Hidden>
            </Box>

            <DocusignAlert />
            {/* <ReferredAlert /> */}

            <Box className={classes.content}>
                <Hidden smDown>
                    <Sidebar />
                </Hidden>
                <Box className={classes.main}>{children}</Box>
            </Box>
        </Box>
    );
}
