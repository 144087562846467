import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: '0 3px 20px #1d26260d',
        backgroundColor: 'white',
        padding: 24,
        borderRadius: 6,

        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    cardTitle: {
        paddingBottom: 12,
        borderBottom: '1px solid #E4E4E4',
    },
    cardContent: {
        paddingTop: 16,
    },
}));

export default useStyles;
