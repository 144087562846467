import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';

export default function Card({ title, titleProps, cardOnly, titleChildren, children, titleSx, contentSx, className, sx, ...props }) {
    const classes = useStyles(props);

    if (cardOnly) {
        return (
            <Box className={classes.card} sx={sx} {...props}>
                {children}
            </Box>
        );
    }

    return (
        <Box className={clsx(classes.card, className)} sx={sx} {...props}>
            <Box className={classes.cardTitle} sx={titleSx}>
                {title && (
                    <Typography variant="h5" {...titleProps}>
                        {title}
                    </Typography>
                )}
                {titleChildren}
            </Box>
            <Box className={classes.cardContent} sx={contentSx}>
                {children}
            </Box>
        </Box>
    );
}
