import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaLocationArrow } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import Logo from 'assets/images/logos/logo-long-white.png';
import useStyles from './styles';
import { BlogLink, SupportEmail, TextLine } from 'utils/constants';

export default function Footer({ sx }) {
    const classes = useStyles();

    return (
        <Box className={classes.root} sx={sx}>
            <Container maxWidth="lg">
                <Box className={classes.divider} sx={{ marginBottom: 5 }} />
                <Box className={classes.content} sx={{ marginBottom: { xs: 7.5, md: 15 } }}>
                    <Box>
                        <img src={Logo.src} className={classes.logo} />
                        <Typography>
                            @{new Date().getFullYear()} |{' '}
                            <a className="links" href="https://thereliefconsultants.com/privacy">
                                Privacy Policy
                            </a>
                        </Typography>
                        <Typography
                            variant="font3"
                            sx={{
                                maxWidth: 300,
                                fontWeight: '400',
                                marginTop: 3,
                                lineHeight: '24px',
                            }}
                        >
                            The Relief Consultants is a specialty advocacy consulting service exclusively dedicated to understanding and
                            maximizing Government Programs intended for the typical American Citizen, Business, or Legal Resident
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ fontWeight: '600', marginBottom: 2 }}>
                            Contact Info
                        </Typography>
                        <Box className={classes.row} sx={{ marginBottom: 2 }}>
                            <FaLocationArrow className={classes.icon} />
                            <Typography sx={{ marginLeft: 1 }}>
                                3525 Del Mar Hts Rd 1880 <br />
                                San Diego, CA 92130
                            </Typography>
                        </Box>
                        <Box className={classes.row} sx={{ marginBottom: 2 }}>
                            <FiPhoneCall className={classes.icon} />
                            <Typography sx={{ marginLeft: 1 }}>Text Us {TextLine}</Typography>
                        </Box>
                        <Box className={classes.row} sx={{ marginBottom: 2 }}>
                            <MdEmail className={classes.icon} />
                            <Typography sx={{ marginLeft: 1 }}>{SupportEmail}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ fontWeight: '600', marginBottom: 1 }}>
                            Quick Links
                        </Typography>
                        <a href="https://thereliefconsultants.com/" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Home</Typography>
                            </Box>
                        </a>
                        <a href="https://thereliefconsultants.com/erc" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">ERC</Typography>
                            </Box>
                        </a>
                        <a href="https://thereliefconsultants.com/unemployment" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Unemployment</Typography>
                            </Box>
                        </a>
                        <a href="https://thereliefconsultants.com/contact" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Contact</Typography>
                            </Box>
                        </a>
                        <a href={BlogLink} target="_blank" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Blog</Typography>
                            </Box>
                        </a>
                        <a href="https://thereliefconsultants.com/privacy" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Privacy</Typography>
                            </Box>
                        </a>
                        <a href="https://thereliefconsultants.com/terms" className="links">
                            <Box className={classes.link}>
                                <Typography variant="font3">Terms & Conditions</Typography>
                            </Box>
                        </a>
                    </Box>
                </Box>
                <Box className={classes.divider} sx={{ marginBottom: 5 }} />
                <Typography variant="font5" sx={{ fontWeight: '600', textAlign: 'center' }}>
                    TRC LLC DOES NOT provide any legal or accounting advice and users of this web site should consult with their own lawyer
                    and C.P.A. for legal and accounting advice.
                </Typography>
            </Container>
        </Box>
    );
}
