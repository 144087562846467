import { makeStyles } from '@mui/styles';
import theme from 'theme';
import Background from 'assets/images/stock/business-mask.jpg';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        gap: 24,
    },
    fullLogo: {
        maxWidth: '230px',
    },

    customReferralCode: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        backgroundColor: theme.palette.primary.main,
        minHeight: 'calc(90vh - 152px)',
        background: `linear-gradient(180deg, rgba(17,110,234,0.9) 100%, rgba(17,110,234,0.9) 100%), url(${Background}) 0% 0% no-repeat padding-box`,
        mixBlendMode: 'darken',
        color: 'white',
        backgroundSize: 'cover',
        position: 'bottom',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),

        [theme.breakpoints.down('md')]: {
            minHeight: 'unset',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    },
    heroGrid: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
        },
    },
    listItem: {
        '& > h5': {
            fontWeight: '600',
        },

        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    listDot: {
        backgroundColor: 'white',
        height: 7,
        width: 7,
        borderRadius: '50%',
        marginRight: theme.spacing(2),
    },

    form: {
        borderRadius: 0,
        padding: '56px 35px 31px 35px',

        [theme.breakpoints.down('sm')]: {
            padding: '36px 20px 31px 20px',
        },
    },
    animation: {
        maxWidth: '40%',
    },
    animationContainer: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default useStyles;
