import React, { useState } from 'react';
// Modules
import { useDispatch } from 'react-redux';
import UserRedux from 'redux/actions/user';
import { useNavigate } from 'react-router-dom';
// Components
import { Box, Button, Container, Fab, Paper, Typography } from '@mui/material';
import Footer from 'components/Widgets/Footer/Footer';
import InformationDialog from 'components/Dialogs/InformationDialog/InformationDialog';
// Icons
import { Info } from '@mui/icons-material';
// Styles
import useStyles from './styles';

const NoGroupStack = (props) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleSignout() {
        dispatch(UserRedux.signOut());
        navigate('/');
    }

    return (
        <Box className={classes.root}>
            <Container maxWidth={'md'} sx={{ flexGrow: 1 }} className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography sx={{ marginBottom: 1 }}>Thanks for signing up! Contact us to get started.</Typography>
                    <Button onClick={handleSignout} variant="contained">
                        Sign Out
                    </Button>
                </Paper>
            </Container>
            <Footer />

            <InformationDialog open={dialog} onClose={() => setDialog(false)} />
            <Fab color="primary" variant="extended" onClick={() => setDialog(true)} className={classes.floatingButton}>
                <Info sx={{ marginRight: 1 }} /> Help
            </Fab>
        </Box>
    );
};

export default NoGroupStack;
