import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/white-abstract.jpg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        height: '100%',
    },
}));

export default useStyles;
