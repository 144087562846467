import { makeStyles } from '@mui/styles';
import theme from 'theme';

export const TOOLBAR_HEIGHT = 50;

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'white',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 64px 0px',
        borderRadius: 3,
        width: 260,
        margin: '16px 0px 16px 16px',
        padding: theme.spacing(3),
        minHeight: 'calc(100vh - 20vh);',
    },

    header: {
        gap: 12,
        display: 'flex',
        flexDirection: 'column',
    },

    content: {
        marginTop: theme.spacing(1),
    },

    item: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
        padding: `0px 12px`,
        color: '#37373C',
        height: 48,
        '& > svg': {
            fontSize: 24,
            marginRight: theme.spacing(1.5),
        },

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },

    activeItem: {
        backgroundColor: '#E1EFFD',
        color: theme.palette.primary.main,
        borderRadius: 10,
    },

    disabledItem: {
        color: 'rgba(0,0,0,0.4)',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(0,0,0,0.3)',
        },
    },

    divider: {
        backgroundColor: '#F8F8F9',
        height: '3px',
        width: '100%',
        margin: '16px 0px',
    },
}));

export default useStyles;
