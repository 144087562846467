import React from 'react';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { InlineWidget } from 'react-calendly';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const CalendlyUrl = 'https://calendly.com/the-relief-consultants/shutdown-credit';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CalendlyDialog({ open, referralCode, onClose, content = {}, asDialog = true, styles }) {
    const location = useLocation();

    const query = queryString.parse(location.search);

    const data = {
        ...content,
        path: 'referral-partner-dashboard/booking',
        environment: process.env.REACT_APP_VERCEL_ENV !== 'production' ? 'Development' : 'Production',
    };

    let utmString = '';
    if (query.utm_campaign) {
        utmString += `&utm_campaign=${query.utm_campaign}`;
    }
    if (query.utm_source) {
        utmString += `&utm_source=${query.utm_source}`;
    }
    if (query.utm_medium) {
        utmString += `&utm_medium=${query.utm_medium}`;
    }
    if (query.utm_term) {
        utmString += `&utm_term=${query.utm_term}`;
    }
    if (query.utm_content) {
        utmString += `&utm_content=${query.utm_content}`;
    }
    if (query.referral_code) {
        data.referral_code = query.referral_code;
    }
    if (referralCode) {
        data.referralCode = referralCode;
    }

    const url = `${CalendlyUrl}?salesforce_uuid=${encodeURI(JSON.stringify(data))}${utmString}`;

    if (!asDialog) {
        return <InlineWidget url={url} styles={styles} />;
    }

    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Close
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ paddingLeft: 4, paddingTop: 5 }}>
                <InlineWidget url={url} styles={{ height: 1000 }} />
            </Box>
        </Dialog>
    );
}
