import React, { useState } from 'react';
import clsx from 'clsx';
// images
import { Box, Container, Hidden, IconButton, Typography } from '@mui/material';
import { AccountCircle, Email, ExitToApp, Menu } from '@mui/icons-material';
// Styles
import Logo from 'assets/images/logos/logo-long-dark.png';
import useStyles from './styles';
import MobileDrawer from './Drawer/Drawer';
import { AuthMenu, NavMenu } from './data';
import { BlogLink, ContactNumber, LinkedInLink, SupportEmail } from 'utils/constants';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserRedux from 'redux/actions/user';
import ALink from 'components/ALink/ALink';
import { FaLinkedin, FaMedium } from 'react-icons/fa';

export default function Navbar(props) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [scrolling, setScrolling] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    React.useEffect(() => {
        setDrawerOpen(false);
    }, [pathname]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScrolling);

        return () => window.removeEventListener('scroll', handleScrolling);
    }, []);

    function handleScrolling() {
        if (window.scrollY >= 75 && !scrolling) {
            setScrolling(true);
        } else if (window.scrollY < 75 && !scrolling) {
            setScrolling(false);
        }
    }

    async function handleLogout() {
        setDrawerOpen(false);
        await dispatch(UserRedux.signOut());
    }

    const MenuItems = user ? AuthMenu : NavMenu;

    return (
        <Box>
            <Box className={classes.toolbar}>
                <Container maxWidth={'lg'} className={clsx(classes.toolbarContent)}>
                    <Box className={classes.center}>
                        <a href={`mailto:${SupportEmail}`} className="links">
                            <Box className={classes.row}>
                                <Email
                                    sx={{
                                        color: '#F5F9FE',
                                        marginRight: 1,
                                        fontSize: 18,
                                    }}
                                />
                                <Typography variant="font3" color="white" sx={{ fontWeight: '400' }}>
                                    {SupportEmail}
                                </Typography>
                            </Box>
                        </a>
                        <Box className={classes.divider} />
                        <a href="tel:858-290-0831" className="links">
                            <Box className={classes.row}>
                                {/* <Phone sx={{ color: '#F5F9FE', marginRight: 1, fontSize: 18 }} /> */}
                                <Typography variant="font3" sx={{ fontWeight: '600' }} color="white">
                                    Text Us
                                </Typography>
                                <Typography variant="font3" color="white" sx={{ fontWeight: '400', marginLeft: 1 }}>
                                    {ContactNumber}
                                </Typography>
                            </Box>
                        </a>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.center}>
                            <Box className={classes.row}>
                                <Typography variant="font3" color="white" sx={{ fontWeight: '400' }}>
                                    Follow us on social!
                                </Typography>
                            </Box>
                            <Box className={classes.divider} />
                            <Box className={classes.row}>
                                <Box component={Link} href={BlogLink} className={classes.row}>
                                    <FaMedium color="#F5F9FE" />
                                </Box>
                                <Box component={Link} href={LinkedInLink} className={classes.row} sx={{ marginLeft: 1 }}>
                                    <FaLinkedin color="#F5F9FE" />
                                </Box>
                            </Box>
                        </Box>
                    </Hidden>
                </Container>
            </Box>
            <Box
                className={clsx(classes.navigation, {
                    [classes.navigationScrolling]: scrolling,
                })}
            >
                <Container
                    maxWidth={'lg'}
                    className={clsx(classes.navigationContent, {
                        [classes.navigationContentScrolling]: scrolling,
                    })}
                >
                    <Link to="/">
                        <img src={Logo} className={classes.logo} />
                    </Link>
                    <Hidden smDown>
                        <Box className={classes.center}>
                            {MenuItems.map((item, key) => {
                                return (
                                    <ALink external={item.external} href={item.href} key={`i-${key}`} className="links">
                                        <Box
                                            className={clsx(classes.navItem, {
                                                [classes.activeNavItem]: pathname === item.href,
                                            })}
                                        >
                                            <Typography variant="font1" sx={{ fontWeight: '500' }}>
                                                {item.title}
                                            </Typography>
                                        </Box>
                                    </ALink>
                                );
                            })}

                            {user && (
                                <>
                                    <IconButton sx={{ marginLeft: 1 }} component={Link} to={`/profile/${user.id}`}>
                                        <AccountCircle />
                                    </IconButton>
                                    <IconButton sx={{ marginLeft: 1 }} onClick={handleLogout}>
                                        <ExitToApp />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton onClick={() => setDrawerOpen(true)}>
                            <Menu sx={{ color: 'black' }} />
                        </IconButton>
                    </Hidden>
                </Container>
            </Box>
            <MobileDrawer open={drawerOpen} onLogout={handleLogout} onClose={() => setDrawerOpen(false)} />
        </Box>
    );
}
