import { makeStyles } from '@mui/styles';

import theme, { AppColors } from 'theme';

const useStyles = makeStyles(() => ({
    root: {},
    stats: {
        display: 'flex',
        gap: 24,

        '& > :first-child': {
            marginBottom: 6,
        },
        textAlign: 'center',

        overflow: 'scroll',
    },
    stat: {
        minWidth: 'fit-content',
    },
    statTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '& > svg': {
            fontSize: 17,
        },
    },
}));

export default useStyles;
