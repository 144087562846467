export const GET_DASHBOARD = 'GET_DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const INIT = null;

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case GET_DASHBOARD:
            return payload;
        case DASHBOARD_ERROR:
        default:
            return state;
    }
}

export default reducer;
