import { makeStyles } from '@mui/styles';
import theme, { AppColors } from 'theme';

export const TOOLBAR_HEIGHT = 50;

const useStyles = makeStyles(() => ({
    referActions: {
        textAlign: 'center',
        display: 'flex',
        marginTop: theme.spacing(1),

        '& > button:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

export default useStyles;
