import { makeStyles } from '@mui/styles';
import theme, { AppColors } from 'theme';

const HEADER_HEIGHT = 80;

const useStyles = makeStyles(() => ({
    /** Drawer */
    logo: {
        width: 200,
    },

    drawer: {
        width: '100%',
        backgroundImage: 'none',
    },
    drawerBox: {
        padding: 20,
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
    },

    links: {
        padding: '0px 20px',
    },

    drawerItem: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #f0f0f0',
        padding: '20px 0px',
        '& > svg': {
            fontSize: 24,
            marginRight: theme.spacing(1.5),
        },
    },

    itemActive: {
        fontWeight: 800,
        borderRadius: 5,
        color: theme.palette.primary.main,
    },

    disabledItem: {
        color: 'rgba(0,0,0,0.4)',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(0,0,0,0.3)',
        },
    },

    divider: {
        margin: '12px 0px',
        width: '100%',
        height: 1,
        backgroundColor: '#ffffff50',
    },

    link: {
        display: 'block',
        marginBottom: theme.spacing(2),
    },
}));

export default useStyles;
