import React from 'react';
// Custom
import { Box } from '@mui/material';
import CalendlyDialog from 'components/Dialogs/CalendlyDialog/CalendlyDialog';
// Styles && Assets
import useStyles from './styles';
import { useSelector } from 'react-redux';

export default function Booking(props) {
    const classes = useStyles();

    const { user } = useSelector(({ user }) => ({ user }));

    return (
        <Box className={classes.root}>
            <CalendlyDialog referralCode={user.ReferralPartner?.code} asDialog={false} styles={{ height: '80vh', width: '100%' }} />
        </Box>
    );
}
