import React from 'react';
// UI
import { Box, Container, Typography } from '@mui/material';
// Styles
import useStyles from './styles';

export default function Resources() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Typography variant="h5" sx={{ fontWeight: '600' }}>
                    Helpful Resources
                </Typography>
                <Typography variant="font3" sx={{ fontWeight: '400', maxWidth: 500 }}>
                    Resources are not yet available, we will be adding resources soon! Thanks for your patience
                </Typography>
                <Box className={classes.iframeContainer} sx={{ marginTop: 2 }}></Box>
            </Container>
        </Box>
    );
}
