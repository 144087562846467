import { makeStyles } from '@mui/styles';

import theme, { AppColors } from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: theme.spacing(2),
    },
    container: {
        paddingBottom: 60,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
    paper: {
        borderRadius: 3,
        overflow: 'hidden',
    },
    emptyTable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
    },
    tableHeaderCell: {
        backgroundColor: '#F3F6F9',

        '& > th': {
            paddingTop: 8,
            paddingBottom: 8,
        },
    },
    tableActions: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
    },
    stats: {
        display: 'flex',
        gap: 24,

        '& > :first-child': {
            marginBottom: 6,
        },
        textAlign: 'center',
    },
    statTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '& > svg': {
            fontSize: 17,
        },
    },
}));

export default useStyles;
