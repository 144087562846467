import * as React from 'react';
import clsx from 'classnames';
// MUI
import { IconButton, Box, Drawer, Typography } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
// images
import Logo from 'assets/images/logos/logo-long-dark.png';
// styles
import useStyles from './styles';
import { AuthMenu, NavMenu, SideBarMenu } from '../data';
import { useSelector } from 'react-redux';
import ALink from 'components/ALink/ALink';
import { LockOutlined } from '@mui/icons-material';

export default function MobileDrawer({ open, onClose, onLogout }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));

    const pathname = window.location.pathname;

    const MenuItems = user ? SideBarMenu : NavMenu;

    return (
        <Drawer anchor={'right'} open={open} classes={{ paper: classes.drawer }}>
            <Box role="presentation" className={classes.drawerBox}>
                <Box className={classes.drawerHeader}>
                    <img src={Logo} className={classes.logo} />
                    <IconButton onClick={onClose}>
                        <AiOutlineClose size={16} />
                    </IconButton>
                </Box>
                <Box className={classes.links}>
                    {MenuItems.map((item, i) => {
                        if (item.divider) return;

                        if (item.masterCode && user.ReferralPartner.masterCode) return null;

                        return (
                            <ALink external={item.external} href={item.href} key={`k-${i}`} className="links">
                                <Box
                                    className={clsx(classes.drawerItem, {
                                        [classes.itemActive]: pathname === item.href,
                                        // [classes.disabledItem]: disabled,
                                    })}
                                >
                                    {/* {disabled ? <LockOutlined /> : item.icon} */}
                                    {item.icon}
                                    <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                        {item.title}
                                    </Typography>
                                </Box>
                            </ALink>
                        );
                    })}

                    {user && (
                        <Box className={clsx(classes.drawerItem)} onClick={onLogout}>
                            <Typography variant="font3" sx={{ fontWeight: '500' }}>
                                Logout
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
}
