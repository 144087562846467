import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { SET_CURRENT_USER, LOGOUT, USER_ERROR } from '../reducers/user';
import cloneDeep from 'lodash/cloneDeep';

export default class UserRedux {
    static getCurrentUser = () => async (dispatch, getState) => {
        const state = getState();
        if (!state.user) return;

        try {
            const response = await axios.get(`${ApiResource.endpoint}/v2/users/current`, {
                headers: {
                    'x-auth': state.user.authToken,
                },
            });

            const payload = { ...state.user, ...response.data.user };
            payload.lastSyncTime = new Date().getTime() / 1000;

            dispatch({
                payload,
                type: SET_CURRENT_USER,
            });

            return payload;
        } catch (error) {
            dispatch(UserRedux.signOut());
            throw error;
        }
    };

    static setUser = (payload) => (dispatch, getState) => {
        const user = cloneDeep(getState().user);

        dispatch({
            type: SET_CURRENT_USER,
            payload: { ...user, ...payload },
        });
    };

    static signOut = () => async (dispatch) => {
        try {
            dispatch({
                payload: null,
                type: LOGOUT,
            });

            return true;
        } catch (error) {
            console.log(error);
            dispatch({
                type: USER_ERROR,
            });

            return false;
        }
    };
}
