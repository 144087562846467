import React from 'react';
// MUI
import { Box, Typography } from '@mui/material';
import { MoonLoader } from 'react-spinners';
// Styles
import useStyles from './styles';

export default function FullscreenLoader({ sx, text }) {
    const classes = useStyles();

    return (
        <Box className={classes.root} sx={sx}>
            <MoonLoader />
            <Typography sx={{ marginTop: 2 }}>{text}</Typography>
        </Box>
    );
}
