import React, { useEffect } from 'react';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import Authbar from 'components/Navbar/Authbar';
import Profile from './Profile/Profile';
import Resources from './Resources/Resources';
import Agreements from './Agreements/Agreements';
import RefLinks from './RefLinks/RefLinks';

import useStyles from './styles';
import Booking from './Booking/Booking';
import { createEvent } from 'integrations/api/events';
import { useSelector } from 'react-redux';
import ReferralInfo from './ReferralInfo/ReferralInfo';
import ReferralTracker from './ReferralTracker/ReferralTracker';
import Downstream from './Downstream/Downstream';

const AuthStack = (props) => {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));

    useEffect(() => {
        createEvent('RP Access', {}, user.authToken);
    }, []);

    return (
        <Authbar>
            <Routes>
                <Route path="/profile" element={<Profile />} />
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/" element={<ReferralTracker />} />
                {/* <Route path="/courses" element={<Courses />} /> */}
                <Route path="/resources" element={<Resources />} />
                <Route path="/referral-tracker" element={<ReferralTracker />} />
                <Route path="/agreements" element={<Agreements />} />
                <Route path="/links" element={<RefLinks />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/down-stream" element={<Downstream />} />
                <Route path="/form/:id" element={<ReferralInfo />} />
                {/* End Routes */}

                <Route path="*" element={<Navigate to={'/'} replace />} />
            </Routes>
        </Authbar>
    );
};

export default AuthStack;
