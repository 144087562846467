import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: theme.spacing(3),
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
    section: {
        backgroundColor: 'white',
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 64px 0px',
    },
    sectionHeader: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
    },
    sectionBody: {
        padding: theme.spacing(2),
    },
    codeBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    copyContent: {
        fontSize: 20,
        marginLeft: theme.spacing(2),
        cursor: 'pointer',
    },
}));

export default useStyles;
