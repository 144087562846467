import React, { useEffect, useState } from 'react';
// Modules
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// UI
import { Box, Typography, Container, Grid } from '@mui/material';

import FullscreenLoader from 'components/Widgets/FullscreenLoader';
// Icons
import { KeyboardBackspace } from '@mui/icons-material';
// Styles
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import FormsRedux from 'redux/actions/forms';
import Card from 'components/Card/Card';

export default function ReferralInfo() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const { user, forms } = useSelector(({ user, forms }) => ({ user, forms }));
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const form = forms.selected || {};

    useEffect(() => {
        if (location.state?.form?.id && location.state?.form?.id === parseInt(id)) {
            dispatch(FormsRedux.setSelected(location.state.form));
            setLoading(false);
        } else getForm();
    }, []);

    async function getForm() {
        setLoading(true);
        try {
            await dispatch(FormsRedux.select(id));
        } catch (error) {
            console.log(error);
            navigate('/forms');
        }
        setLoading(false);
    }

    if (loading) return <FullscreenLoader />;

    let percent, credit, totalCredit, commission;
    if (form.type === 'self-employment') {
        percent = 25 / 100;
        totalCredit = form?.Credit?.total ?? 0;
        credit = (form?.Credit?.total ?? 0) * percent;
        if (user.ReferralPartner.isMaster) {
            commission = (totalCredit * user?.ReferralPartner?.percent) / 100;
        } else commission = (credit * user?.ReferralPartner?.percent) / 100;
    } else if (form.type === 'ERC') {
        percent = (form.docusign?.clientContract?.percent ?? 15) / 100;
        totalCredit = form?.Credit?.breakdown?.eligible?.credit?.both ?? 0;
        credit = (form?.Credit?.breakdown?.eligible?.credit?.both ?? 0) * percent;
        if (user.ReferralPartner.isMaster) {
            commission = (totalCredit * user?.ReferralPartner?.percent) / 100;
        } else commission = (credit * user?.ReferralPartner?.percent) / 100;
    } else if (form.type === 'visa-settlement') {
        credit = form?.Credit?.total || '-';
        totalCredit = form?.Credit?.total || '-';
    }

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.header} component={Link} to="/referral-tracker">
                    <KeyboardBackspace />
                    <Typography variant="font3" sx={{ fontWeight: '600', marginLeft: 1 }}>
                        Go Back
                    </Typography>
                </Box>
                <Card title={`${form.type?.titleCase?.()} Application Information`} sx={{ marginTop: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={8}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ fontWeight: '600' }}>
                                        Status: {form.status}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="font3" color="darkText.main">
                                        Client Name
                                    </Typography>
                                    <Typography variant="font1">{`${form.firstName} ${form.lastName}`}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="font3" color="darkText.main">
                                        Client Contact
                                    </Typography>
                                    <Typography variant="font1">{`${form.email} | ${form.phone}`}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="font3" color="darkText.main">
                                        Expected Credit for Client
                                    </Typography>
                                    <Typography variant="font1" fontWeight={'600'} color={totalCredit !== 0 ? 'success.main' : null}>
                                        {totalCredit !== 0 ? `$${totalCredit?.numberWithCommas(2)}` : 'Not Available Yet'}
                                    </Typography>
                                </Grid>
                                {/* {!user.ReferralPartner.masterCode && (
                                    <Grid item xs={12}>
                                        <Typography variant="font3" color="darkText.main">
                                            Expected Commission
                                        </Typography>
                                        <Typography variant="font1">
                                            {commission !== 0 ? `$${commission?.numberWithCommas(2)}` : 'Not Available Yet'}
                                        </Typography>
                                    </Grid>
                                )} */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </Box>
    );
}
