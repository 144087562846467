export const GET_FORMS = 'GET_FORMS';
export const SET_FORMS = 'SET_FORMS';
export const FORMS_ERROR = 'FORMS_ERROR';
export const INIT = { count: 0, items: [], selected: null };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case GET_FORMS:
        case SET_FORMS:
            return payload;
        case FORMS_ERROR:
        default:
            return state;
    }
}

export default reducer;
