import React, { useState } from 'react';
import useStyles from './styles';
// UI
import { Box, Container, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { LoadingButton } from '@mui/lab';
import UserRedux from 'redux/actions/user';
import { Refresh } from '@mui/icons-material';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
// Styles

export default function Agreements() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [sending, setSending] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [sigType, setSigType] = useState('ssn');
    const dispatch = useDispatch();

    async function handleSendDocusign() {
        setSending(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/partners/docusign/send`,
                { sigType },
                {
                    headers: { 'x-auth': user.authToken },
                },
            );
            dispatch(UserRedux.setUser(response.data));
            dispatch(setSnackbar({ message: 'Contract will arrive in your inbox shortly' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSending(false);
    }

    async function handleRefresh() {
        setRefreshing(true);
        try {
            await dispatch(UserRedux.getCurrentUser());
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setRefreshing(false);
    }

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Box className={classes.section}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            Docusign Agreements
                        </Typography>
                        <IconButton disabled={refreshing} color="secondary" onClick={handleRefresh}>
                            <Refresh />
                        </IconButton>
                    </Box>
                    <Box className={classes.sectionBody}>
                        {user?.ReferralPartner?.docusign?.agreement?.status !== 'completed' && (
                            <>
                                <Typography variant="font2" sx={{ fontWeight: '400', maxWidth: 600, marginBottom: 1 }}>
                                    <span className="bold">Status:</span> Waiting for signatures
                                </Typography>
                                <Typography variant="font3" sx={{ fontWeight: '400', marginBottom: 2 }}>
                                    (Note: After signing it may take between 1-5 minutes for the data to propogate)
                                </Typography>
                                <RadioGroup onChange={(event, value) => setSigType(value)} value={sigType} sx={{ marginBottom: 1 }}>
                                    <FormControlLabel
                                        value="ssn"
                                        control={<Radio />}
                                        label="I am an Individual or Sole Proprietor with an SSN"
                                    />
                                    <FormControlLabel
                                        value="ein"
                                        control={<Radio />}
                                        label="I am an LLC, Corporation, or Sole Proprietor with an EIN"
                                    />
                                </RadioGroup>
                                <LoadingButton onClick={handleSendDocusign} loading={sending} variant="contained">
                                    Click here to send agreement to {user?.email}
                                </LoadingButton>
                            </>
                        )}
                        {user?.ReferralPartner?.docusign?.agreement?.status === 'completed' && (
                            <Typography variant="font2" sx={{ fontWeight: '400', maxWidth: 600 }}>
                                <span className="bold">Status:</span> Your agreement has been signed.{' '}
                                <a
                                    className="link"
                                    href={`https://app.docusign.com/documents/details/${user?.ReferralPartner?.docusign?.agreement?.envelopeId}`}
                                >
                                    View Agreement
                                </a>
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
