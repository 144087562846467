import { makeStyles } from "@mui/styles";

import theme, { AppColors } from "theme";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: theme.spacing(6),
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  progress: {
    flexBasis: "40%",

    [theme.breakpoints.down("sm")]: {
      flexBasis: "auto",
      width: "100%",

      marginTop: theme.spacing(4),
    },
  },

  listAvatar: {
    height: 40,
    width: 40,
    minHeight: 40,
    minWidth: 40,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  finished: {
    backgroundColor: AppColors.Success,
  },
  notStarted: {
    backgroundColor: "transparent",
    border: "1px solid rgba(0,0,0,0.4)",
    color: "rgba(0,0,0,0.4)",
  },
  notStartedText: {
    "& > span": {
      color: "rgba(0,0,0,0.4)",
    },
    "& > p": {
      color: "rgba(0,0,0,0.4)",
    },
  },
  responsesLink: {
    fontWeight: "400",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },

  alertBody: {
    display: "flex",
    flexDirection: "column",

    "& > button": {
      alignSelf: "flex-end",
    },
  },

  sendSecureLink: {
    padding: theme.spacing(2),
  },
  secureLinkActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useStyles;
