// UI
import { Box, Button, InputAdornment, MenuItem, Paper, Skeleton, TextField, Typography } from '@mui/material';
import ContactDialog from 'components/Dialogs/ContactDialog/ContactDialog';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import LeadsRedux from 'redux/actions/leads';
import { LEAD_STATUS_TO_READABLE, TYPE_OF_LEAD } from 'utils/constants';
import { Search } from '@mui/icons-material';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import NotesDialog from 'components/Dialogs/NotesDialog/NotesDialog';

const TableHeaders = ['Name', 'Status', 'Booking Type', 'Referred By', 'Last Updated', ''];
const translation = {
    Name: 'firstName',
    Status: 'status',
    'Referred By': 'ReferralPartner.User.name',
    'Booking Type': 'metadata.from',
    'Last Updated': 'updatedAt',
};

export default function Leads() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const query = queryString.parse(location.search);
    const [search, setSearch] = useState(query.search || '');
    const [status, setStatus] = useState('Submitted');
    const [notes, setNotes] = useState(false);
    const { user, leads } = useSelector(({ user, leads }) => ({ user, leads }));
    const dispatch = useDispatch();
    const [contactDialog, setContactDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Last Updated',
    });

    useEffect(() => {
        getleads();
    }, [sort, page, perPage, status]);

    async function getleads() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];
            await dispatch(
                LeadsRedux.get({
                    perPage,
                    page,
                    status,
                    order: sort.order,
                    orderBy: orderByTl,
                    search,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    function handleViewNotes(item) {
        setNotes(item);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Paper className={classes.paper}>
            <ContactDialog
                onSubmit={() => {
                    setContactDialog(false);
                    getleads();
                }}
                type={contactDialog}
                open={Boolean(contactDialog)}
                referralCode={user.ReferralPartner.code}
                onClose={() => setContactDialog(false)}
            />
            <NotesDialog type="Lead" item={notes} open={Boolean(notes)} onClose={() => setNotes(false)} />
            <Box className={classes.tableActions}>
                {/* <Typography variant="h5" sx={{ fontWeight: '600', marginBottom: 1 }}></Typography> */}
                <TextField
                    size="small"
                    placeholder="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            getleads();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button sx={{ textTransform: 'none' }} onClick={getleads}>
                                    Search
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    select
                    size="small"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Search by Status"
                    sx={{ minWidth: '200px' }}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value={''}>All</MenuItem>
                    {Object.keys(LEAD_STATUS_TO_READABLE).map((key) => {
                        return <MenuItem value={key}>{LEAD_STATUS_TO_READABLE[key]}</MenuItem>;
                    })}
                </TextField>
            </Box>

            <CustomTable
                classes={{ tableHeaderCell: classes.tableHeaderCell }}
                count={leads.count}
                headers={TableHeaders}
                onSort={handleOnSort}
                rowsPerPage={perPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={4}
                pagination={leads.count !== 0}
                rows={
                    loading
                        ? Array.from(new Array(5)).map((_, i) => {
                              return {
                                  columns: [<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />],
                              };
                          })
                        : leads.items.map((item) => {
                              return {
                                  columns: [
                                      <Typography variant="font2" sx={{ fontWeight: '500' }}>
                                          {item.firstName} {item.lastName}
                                      </Typography>,
                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                          {LEAD_STATUS_TO_READABLE[item.status]}
                                      </Typography>,
                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                          {item.metadata.from ?? 'Call Back'}
                                          {item.metadata.scheduledTime
                                              ? ` at ${dayjs
                                                    .utc?.(item.metadata.scheduledTime)
                                                    ?.tz?.(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                                    .format('MMM DD, YYYY hh:mma')}`
                                              : null}
                                      </Typography>,
                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                          {item?.ReferralPartner?.User?.name}
                                      </Typography>,
                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                          {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                      </Typography>,
                                      <Button disabled={item.notesCount === 0} onClick={() => handleViewNotes(item)}>
                                          View Notes
                                      </Button>,
                                  ],
                              };
                          })
                }
            />
            {!loading && leads.count === 0 && (
                <Box className={classes.emptyTable}>
                    <Typography variant="font4">You have no leads yet.</Typography>
                </Box>
            )}
        </Paper>
    );
}
