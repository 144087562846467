import React, { useEffect, useRef } from 'react';
// Modules
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import UserRedux from 'redux/actions/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { createEvent } from 'integrations/api/events';

// Components
import FullscreenLoader from 'components/Widgets/FullscreenLoader';

export default function Authenticate() {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const navigate = useNavigate();
    const authenticating = useRef();

    useEffect(() => {
        handleAuthenticate();
    }, []);

    async function handleAuthenticate() {
        if (authenticating.current) return;
        authenticating.current = true;

        const { email, token, redirect } = queryString.parse(search);
        try {
            const response = await axios.post(`${ApiResource.endpoint}/v2/authentication/authenticate`, {
                email,
                password: token,
            });

            createEvent('RP Authenticate', {}, response.data.token);

            dispatch(UserRedux.setUser({ authToken: response.data.token, ...response.data.user }));

            if (redirect) {
                navigate(redirect);
            }
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
            navigate('/');
        }
    }

    return <FullscreenLoader text="Authenticating..." sx={{ minHeight: '40vh' }} />;
}
