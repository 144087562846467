import { makeStyles } from '@mui/styles';
import theme, { AppColors } from 'theme';

export const TOOLBAR_HEIGHT = 50;

const useStyles = makeStyles(() => ({
    toolbar: {
        width: '100%',
        height: TOOLBAR_HEIGHT,
        backgroundColor: theme.palette.primary.main,
    },
    toolbarContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    divider: {
        width: 1,
        height: 25,
        backgroundColor: '#689ad7',
        opacity: 0.5,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    referActions: {
        textAlign: 'center',
        display: 'flex',
        marginTop: theme.spacing(1),

        '& > button:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    navigation: {
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 64px 0px',
        padding: '24px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        width: '100%',
        zIndex: 99,

        transition: '0.2s all ease-in',
    },
    authNav: {
        boxSizing: 'border-box',
        padding: '12px 24px',
        top: 0,
    },
    navigationScrolling: {
        position: 'fixed',
        top: 0,
        height: 86,
        padding: 0,
    },

    navigationContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
    },
    logo: {
        height: 50,

        [theme.breakpoints.down('sm')]: {
            height: 37.5,
        },
    },
    navItem: {
        marginLeft: theme.spacing(4),

        color: 'black',
        borderRadius: 24,
        transition: '0.2s all ease-in-out',
        cursor: 'pointer',
        textDecoration: 'none',

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },

    activeNavItem: {
        color: theme.palette.primary.main,
    },

    /** Auth bar */
    content: {
        display: 'flex',
        alignItems: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    main: {
        flex: 1,
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: AppColors.Success,
        padding: '4px 10px',
        borderRadius: 4,
        color: 'white',
    },
    statusInactive: {
        backgroundColor: AppColors.Yellow,
    },

    /** Reused */
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    button: {
        textTransform: 'uppercase',
        padding: '16px 24px',
        color: 'white',
    },
}));

export default useStyles;
