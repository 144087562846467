import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: { paddingTop: theme.spacing(3) },
    title: {
        display: 'flex',
        alignItems: 'center',
    },

    section: {
        backgroundColor: 'white',
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 64px 0px',
    },
    sectionHeader: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    sectionBody: {
        padding: '50px 16px 20px 16px',
    },
}));

export default useStyles;
