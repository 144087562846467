// UI
import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import Card from 'components/Card/Card';

const CREDITS = [
    {
        key: 'creditInProgress',
        refkey: 'downstreamCreditInProgress',
        masterkey: 'masterCreditInProgress',
        title: 'Credits in Progress',
        tooltip: 'Sum of the credits currently being calculated and assembled by The Relief Consultants',
    },
    {
        key: 'creditAwaiting',
        refkey: 'downstreamCreditAwaiting',
        masterkey: 'masterCreditAwaiting',
        title: 'Awaiting Signatures',
        tooltip: 'Sum of the credits submitted to clients for sign off',
    },
    {
        key: 'creditSubmitted',
        refkey: 'downstreamCreditSubmitted',
        masterkey: 'masterCreditSubmitted',
        title: 'Submitted to IRS',
        tooltip: 'Sum of credits submitted to the IRS',
    },
    {
        key: 'collectingCommission',
        refkey: 'downstreamCollectingCommission',
        masterkey: 'masterCollectingCommission',
        title: 'Commissionable',
        tooltip: 'Sum of credits received by the client',
    },
    {
        key: 'dealPaid',
        refkey: 'downstreamDealPaid',
        masterkey: 'masterDealPaid',
        title: 'Deal Paid',
        tooltip: 'Sum of credits received and collecting from client',
    },
];

export default function ReferralStats({ tab }) {
    const classes = useStyles();
    const [loadingStats, setLoadingStats] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const [stats, setStats] = useState({});

    useEffect(() => {
        getStats();
    }, []);

    async function getStats() {
        setLoadingStats(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/partners/downstream/credit`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });
            setStats(response.data);
        } catch (error) {
            console.log(error);
        }
        setLoadingStats(false);
    }

    return (
        <Box className={classes.root}>
            {tab !== 0 && (
                <Card title="My Credits" className={classes.paper} sx={{ mb: 2 }}>
                    <Box className={classes.stats}>
                        {!loadingStats &&
                            CREDITS.map((keys, i) => {
                                let color = 'success.main';
                                if (!stats[keys.key]) {
                                    color = '#000';
                                }

                                return (
                                    <Box key={i} className={classes.stat}>
                                        {/* Note if 0 turn yellow, green if not 0 */}
                                        <Tooltip arrow placement="top" title={keys.tooltip}>
                                            <Box className={classes.statTitle}>
                                                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                    {keys.title}
                                                </Typography>
                                                <InfoOutlined />
                                            </Box>
                                        </Tooltip>
                                        <Typography variant="h5" color={color} sx={{ fontWeight: '600', marginBottom: 1 }}>
                                            ${stats?.[keys.key]?.numberWithCommas?.(2) || '0.00'}
                                        </Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                </Card>
            )}
            {tab !== 0 && (
                <Card title="Sub-RP Network Credits" className={classes.paper} sx={{ mb: 2 }}>
                    <Box className={classes.stats}>
                        {!loadingStats &&
                            CREDITS.map((keys, i) => {
                                const key = !user.ReferralPartner.isMaster ? 'refkey' : 'masterkey';
                                let color = 'success.main';
                                if (!stats[keys[key]]) {
                                    color = '#000';
                                }

                                return (
                                    <Box key={i} className={classes.stat}>
                                        {/* Note if 0 turn yellow, green if not 0 */}
                                        <Tooltip arrow placement="top" title={keys.tooltip}>
                                            <Box className={classes.statTitle}>
                                                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                    {keys.title}
                                                </Typography>
                                                <InfoOutlined />
                                            </Box>
                                        </Tooltip>
                                        <Typography variant="h5" color={color} sx={{ fontWeight: '600', marginBottom: 1 }}>
                                            ${stats?.[keys[key]]?.numberWithCommas?.(2) || '0.00'}
                                        </Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                </Card>
            )}
        </Box>
    );
}
