import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Slide,
    TextField,
    Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import phoneModule from 'phone';
import validator from 'validator';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotesDialog({ open, type = 'Form', item, onClose }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState({ items: [], count: 0 });

    useEffect(() => {
        if (item) getNotes();
    }, [item]);

    async function getNotes() {
        setLoading(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/partners/notes/${type}/${item.id}`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });
            setNotes(response.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    return (
        <Dialog classes={{ paper: classes.root }} open={open} maxWidth="sm" fullWidth onClose={onClose} TransitionComponent={Transition}>
            <Box className={classes.content}>
                <Box className={classes.title}>
                    <Typography variant="h5" fontWeight={'600'}>
                        Client Notes
                    </Typography>
                </Box>
                {loading && (
                    <Box className={classes.loader}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Box className={classes.body}>
                        <List>
                            {notes.items.map((note, i) => {
                                return (
                                    <ListItem key={`note-${i}`}>
                                        <ListItemText
                                            primary={note.body}
                                            secondary={`- ${note.User?.name} at ${dayjs(note.createdAt).format('M/D/YY h:mm a')}`}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                )}
                <Box className={classes.footer}>
                    <Button onClick={onClose} variant="contained">
                        Close
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
