import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { SideBarMenu } from '../data';
// images
import { Box, Button, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { LinkSharp, LockOutlined } from '@mui/icons-material';
import { BsFillLightningFill } from 'react-icons/bs';

export default function Sidebar(props) {
    const classes = useStyles();
    const { pathname } = useLocation();
    const { user } = useSelector(({ user }) => ({ user }));

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography variant="h6" sx={{ fontWeight: '600', marginLeft: 1 }}>
                    Partner Office
                </Typography>
                <Button startIcon={<BsFillLightningFill />} fullWidth variant="contained" component={Link} to="/links">
                    Get Your Links
                </Button>
            </Box>

            <Box className={classes.content}>
                {SideBarMenu.map((item, i) => {
                    if (item.divider) {
                        return <Box key={`item-${i}`} className={classes.divider} />;
                    }

                    if (item.masterCode && user.ReferralPartner.masterCode) return null;

                    if (item.mobileOnly) return;

                    if (item.external) {
                        return (
                            <Box
                                key={`item-${i}`}
                                component={'a'}
                                // component={disabled ? 'div' : 'a'}
                                href={item.href}
                                target="_blank"
                                className={clsx(classes.item, {
                                    [classes.activeItem]: pathname === item.href,
                                    // [classes.disabledItem]: disabled,
                                })}
                            >
                                {/* {disabled ? <LockOutlined /> : item.icon} */}
                                {item.icon}
                                <Typography>{item.title}</Typography>
                            </Box>
                        );
                    }

                    return (
                        <Box
                            key={`item-${i}`}
                            component={Link}
                            // component={disabled ? 'div' : Link}
                            to={item.href}
                            // disabled={disabled}
                            className={clsx(classes.item, {
                                [classes.activeItem]: pathname === item.href,
                                // [classes.disabledItem]: disabled,
                            })}
                        >
                            {/* {disabled ? <LockOutlined /> : item.icon} */}
                            {item.icon}
                            <Typography>{item.title}</Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
