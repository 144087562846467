import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/white-abstract.jpg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    floatingButton: {
        position: 'fixed',
        bottom: '3%',
        right: '3%',
        textTransform: 'none',
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

export default useStyles;
