import { makeStyles } from '@mui/styles';
import BGImage from 'assets/images/resources/irs.jpg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

export default useStyles;
