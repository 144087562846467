import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    content: {
        textAlign: 'center',
        paddingTop: theme.spacing(3),
    },
}));

export default useStyles;
