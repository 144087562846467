export const GET_DOWNSTREAM = 'GET_DOWNSTREAM';
export const SET_DOWNSTREAM = 'SET_DOWNSTREAM';
export const DOWNSTREAM_ERROR = 'DOWNSTREAM_ERROR';
export const INIT = { count: 0, items: [], selected: null };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case GET_DOWNSTREAM:
        case SET_DOWNSTREAM:
            return payload;
        case DOWNSTREAM_ERROR:
        default:
            return state;
    }
}

export default reducer;
