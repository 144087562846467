import React from 'react';
// images
import { Alert, Button } from '@mui/material';
// Styles
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function DocusignAlert() {
    const { user } = useSelector(({ user }) => ({ user }));

    if (!user.ReferralPartner.masterCode && user?.ReferralPartner?.docusign?.agreement?.status !== 'completed') {
        return (
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" component={Link} to="/agreements">
                        Send Document
                    </Button>
                }
            >
                You have not yet signed the Referral Partner agreement and W9 contracts. You must sign these in order to be paid by
                referrals.
            </Alert>
        );
    }

    return null;
}
