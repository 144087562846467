import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    content: {
        // padding: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    root: {
        borderRadius: 22,
        padding: theme.spacing(4),
    },

    footer: {
        paddingTop: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
