import React from 'react';
// Components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Widgets/Footer/Footer';

import Home from './Home/Home';
import Authenticate from './Authenticate/Authenticate';
import Login from './Login/Login';

const UnauthStack = (props) => {
    const location = useLocation();

    return (
        <>
            <Navbar />
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="authenticate" element={<Authenticate />} />
                {/* End Routes */}

                <Route
                    path="*"
                    element={
                        <Navigate
                            to=""
                            state={{
                                redirect: `${window.location.pathname}${window.location.search}`,
                            }}
                            replace
                        />
                    }
                />
            </Routes>
            <Footer />
        </>
    );
};

export default UnauthStack;
