const { default: axios } = require('axios');
const { default: ApiResource } = require('./api_resource');

export async function createEvent(name, metadata, authToken) {
    await axios.post(
        `${ApiResource.endpoint}/v2/events`,
        {
            name,
            metadata,
        },
        {
            headers: { 'x-auth': authToken },
        },
    );
}
