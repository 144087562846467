import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import phone from 'phone';
// UI
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
// Styles
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import UserRedux from 'redux/actions/user';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Profile() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));

    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            phone: user?.phone,
            name: user?.name,
            address: user?.address,
        },
    });

    useEffect(() => {}, []);

    async function handleSave(data) {
        setSaving(true);
        try {
            await axios.patch(`${ApiResource.endpoint}/v2/users/profile/${user.id}`, data, {
                headers: {
                    'x-auth': user.authToken,
                },
            });

            await dispatch(UserRedux.getCurrentUser());
            dispatch(setSnackbar({ severity: 'success', message: 'Profile updated' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSaving(false);
    }

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Box className={classes.section}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h5" sx={{ fontWeight: '600' }}>
                            My Profile
                        </Typography>
                    </Box>
                    <Box className={classes.sectionBody}>
                        <Grid container spacing={4} sx={{ marginTop: 1 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={true}
                                    value={user?.email}
                                    onChange={() => {}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Name"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors?.name?.message}
                                    error={!!errors.name}
                                    {...register('name', {
                                        required: 'Add a name to your profile',
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Phone Number"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.phone}
                                    helperText={errors?.phone?.message}
                                    {...register('phone', {
                                        required: 'Invalid Phone Number',
                                        validate: (value) => phone(value).isValid,
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Address"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.address}
                                    helperText={errors?.address?.message}
                                    {...register('address', {})}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton loading={saving} onClick={handleSubmit(handleSave)} variant="contained">
                                    Save Profile
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
