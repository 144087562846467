import { combineReducers } from 'redux';

// Import reducers here
import user from './user';
import forms from './forms';
import leads from './leads';
import downstream from './downstream';
import snackbar from './snackbar';
import dashboard from './dashboard';

const rootReducer = combineReducers({
    user,
    forms,
    leads,
    snackbar,
    dashboard,
    downstream,
});

export default rootReducer;
