import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    body: {
        fontSize: 14,
        fontWeight: 400,
    },
    head: {
        whiteSpace: 'nowrap',
    },
    tableHeaderCell: {},
    tableSortLabel: {
        fontSize: 16,
        // color: theme.palette.common.white,
        '&:hover': {
            // color: theme.palette.common.white,
        },
    },
    tableSortLabelActive: {
        // color: `${theme.palette.common.white} !important`,
        textDecoration: 'underline',
    },
}));

export default useStyles;
