import React, { useState } from 'react';
import { Box, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import phoneModule from 'phone';
import validator from 'validator';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactDialog({ open, type = 'ERC', onClose, referralCode, onSubmit }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    const location = useLocation();
    const query = queryString.parse(location.search);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({});

    async function handleProcessSubmit(data) {
        setSubmitting(true);
        try {
            const { phone, email, ...rest } = data;
            const _refCode = referralCode ? referralCode : query.referral_code;

            await axios.post(
                `${ApiResource.endpoint}/partners/leads`,
                {
                    phone: phoneModule(phone).phoneNumber,
                    email: email,
                    type,
                    metadata: {
                        referralCode: _refCode,
                        ...rest,
                        ...query,
                    },
                },
                {
                    headers: {
                        'x-auth': user.authToken,
                    },
                },
            );
            reset();
            onSubmit?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog classes={{ paper: classes.root }} open={open} maxWidth="sm" fullWidth onClose={onClose} TransitionComponent={Transition}>
            <form onSubmit={handleSubmit(handleProcessSubmit)}>
                <Box className={classes.content}>
                    <Box className={classes.title}>
                        <Typography variant="h3" fontWeight={'700'} mb={1}>
                            Add New {type} Lead
                        </Typography>
                    </Box>

                    <Box className={classes.body}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    fullWidth
                                    label="First Name"
                                    error={!!errors.firstName}
                                    helperText={errors?.firstName?.message}
                                    {...register('firstName', {
                                        required: 'Required Field',
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    fullWidth
                                    label="Last Name"
                                    error={!!errors.lastName}
                                    helperText={errors?.lastName?.message}
                                    {...register('lastName', {
                                        required: 'Required Field',
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    fullWidth
                                    label="Email"
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    {...register('email', {
                                        required: 'Invalid Email',
                                        validate: (value) => validator.isEmail(value),
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    fullWidth
                                    label="Phone Number"
                                    error={!!errors.phone}
                                    helperText={errors?.phone?.message}
                                    {...register('phone', {
                                        required: 'Invalid Phone Number',
                                        validate: (value) => phoneModule(value).isValid,
                                    })}
                                />
                            </Grid>
                            {type === 'ERC' && (
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        sx={{ marginBottom: 1 }}
                                        error={!!errors.companyName}
                                        helperText={errors?.companyName?.message}
                                        fullWidth
                                        label="Company Name"
                                        {...register('companyName', {
                                            required: 'Field Required',
                                        })}
                                    />
                                </Grid>
                            )}
                            {type === 'ERC' && (
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>How many employees did you have on average in 2020-2021</InputLabel>
                                        <Select
                                            sx={{ minWidth: '300px', marginBottom: 1 }}
                                            label="How many employees did they have on average in 2020-2021"
                                            error={!!errors.employees}
                                            helperText={errors?.employees?.message}
                                            {...register('employees', {
                                                required: 'Field Required',
                                            })}
                                        >
                                            <MenuItem value={'Idk'}>I Don't know</MenuItem>
                                            <MenuItem value={'No Employees'}>I didn't have any Employees</MenuItem>
                                            <MenuItem value={'1-10 Employees'}>1-10 Employees</MenuItem>
                                            <MenuItem value={'11-40 Employees'}>11-40 Employees</MenuItem>
                                            <MenuItem value={'40+ Employees'}>40+ Employees</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={12} md={12}>
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    error={!!errors.notes}
                                    helperText={errors?.notes?.message}
                                    fullWidth
                                    multiline={true}
                                    minRows={3}
                                    placeholder="Notes -- Give us information about this lead. Do they know we will contact them? Have they been vetted?"
                                    {...register('notes', {
                                        required: 'Field Required',
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.footer}>
                        <input type="submit" style={{ display: 'none' }} />
                        <LoadingButton
                            variant="contained"
                            sx={{ paddingX: { xs: 3, md: 6 }, paddingY: 1 }}
                            loading={submitting}
                            onClick={handleSubmit(handleProcessSubmit)}
                            size="large"
                        >
                            Submit Lead
                        </LoadingButton>
                    </Box>
                </Box>
            </form>
        </Dialog>
    );
}
