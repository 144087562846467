import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'black',
        color: 'white',
        padding: '100px 0px 30px 0px',

        [theme.breakpoints.down('sm')]: {
            padding: '50px 20px 30px 20px',
        },
    },

    content: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',

            '& > div:not(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
    },

    logo: {
        width: 200,

        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    },

    link: {
        marginTop: theme.spacing(0.6),
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    divider: {
        width: '100%',
        height: 1,
        backgroundColor: '#212326',
    },

    icon: {
        color: theme.palette.primary.main,
    },
    row: {
        display: 'flex',
        alignItems: 'center',

        '&:first-child': {
            marginRight: theme.spacing(1),
        },
    },
}));

export default useStyles;
