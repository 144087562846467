import React from 'react';
import { Link } from 'react-router-dom';

export default function ALink({ external, href, children, ...props }) {
    if (external) {
        return (
            <a href={href} {...props}>
                {children}
            </a>
        );
    }

    return (
        <Link to={href} {...props}>
            {children}
        </Link>
    );
}
