import { makeStyles } from '@mui/styles';

import theme, { AppColors } from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        padding: '20px 12px',
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.12)',
    },
    inner: {
        border: 'none',
    },
    content: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 12,
        },
    },
    stats: {
        display: 'grid',
        flex: 1,
        gridTemplateColumns: 'repeat(4, 1fr)', // Creates 4 equal-width columns
        gap: theme.spacing(2),

        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)', // Creates 4 equal-width columns
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)', // Creates 4 equal-width columns
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: 12,
        flexBasis: '20%',
    },
    downstreams: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        borderTop: '1px solid rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        paddingLeft: 12,
    },

    hoverUnderline: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    stat: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',

        gap: 6,

        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
        },
    },
}));

export default useStyles;
