// UI
import { Box, Container, Tab, Tabs } from '@mui/material';
// Styles
import useStyles from './styles';
import Applications from './Applications/Applications';
import Leads from './Leads/Leads';
import ReferralStats from './ReferralStats/ReferralStats';
import { useState } from 'react';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
import Card from 'components/Card/Card';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ReferralTracker() {
    const classes = useStyles();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [tab, setTab] = useState(query.tab === 'Leads' ? 1 : 0);

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Card cardOnly sx={{ mb: 2 }}>
                    <Tabs variant="scrollable" value={tab} onChange={(_, v) => setTab(v)}>
                        <Tab label="Visa Mastercard Settlement" />
                        <Tab label="Employee Retention Credit" />
                        <Tab label="Self Employment Tax Credit" />
                        <Tab label="Leads" />
                    </Tabs>
                </Card>

                <ReferralStats tab={tab} />

                <TabPanel value={tab} index={0}>
                    <Applications type="visa-settlement" />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Applications type="ERC" />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Applications type="self-employment" />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <Leads />
                </TabPanel>
            </Container>
        </Box>
    );
}
