import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    content: {
        // padding: theme.spacing(2),
    },
    title: {
        textAlign: 'left',
        padding: '24px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
    root: {
        borderRadius: 6,
    },

    loader: {
        textAlign: 'center',
        padding: 24,
    },

    body: {
        maxHeight: 500,
        overflowY: 'scroll',
    },

    footer: {
        borderTop: '1px solid rgba(0,0,0,0.1)',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

export default useStyles;
