import React from 'react';
import {
    AppBar,
    Box,
    Dialog,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationDialog({ open, onClose }) {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                    >
                        Close
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ paddingLeft: 4, paddingTop: 5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font2">
                            Ask these questions to start, and start the form!
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="font3">
                                    Hi this is ... with the relief consultants,
                                    i saw you had an appointment regaurding your
                                    potential Employee Retention Credit.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="font3">
                                    What do you know so far about ERC?
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="font3">
                                    If they say they don't know much, say "The
                                    employee retention credit is a payroll tax
                                    credit, meaning the government will give you
                                    money back if you paid w2 employees in 2020
                                    and 2021, as a reward for keeping employees
                                    on during covid"
                                </Typography>
                                <Typography variant="font3">
                                    How many W2 Employees did you have in 2020
                                    or 2021 on average?
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="font3">
                                    How many employees do you have?
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="font3">
                                    If you qualify you could get up to .... -
                                    ......
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}
