import React, { useState } from 'react';
// Custom
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, TableSortLabel, TablePagination } from '@mui/material';
// Styles
import useStyles from './styles';
import { InfoOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function CustomTable(props) {
    const {
        onSort,
        pagination = true,
        count = 0,
        headers = [],
        rowsPerPage = 25,
        page,
        rows = [],
        onChangePage,
        onChangeRows,
        tooltips = {},
        initialOrderIndex = 2,
        perPageOptions = [5, 10, 25, 50],
    } = props;

    const classes = useStyles(props);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(initialOrderIndex); // 2 is the index for the column 'date'

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if (typeof onSort === 'function') {
            onSort?.(newOrder, index);
            return;
        }
    }

    function handlePageChange(e, page) {
        if (typeof onChangePage !== 'undefined') {
            onChangePage?.(e, page);
        }
    }

    function handleChangeRowsPerPage(e) {
        if (typeof onChangeRows !== 'undefined') {
            onChangeRows?.(e);
        }
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell
                                    className={classes.tableHeaderCell}
                                    classes={{
                                        body: classes.body,
                                        head: classes.head,
                                    }}
                                    key={`header-${index}`}
                                >
                                    {header && onSort && (
                                        <TableSortLabel
                                            active={orderBy === index}
                                            direction={order}
                                            onClick={(e) => handleRequestSort(e, index)}
                                            classes={{
                                                root: classes.tableSortLabel,
                                                active: classes.tableSortLabelActive,
                                                icon: classes.tableSortLabelActive,
                                            }}
                                        >
                                            {header}
                                            {tooltips[header] && (
                                                <Tooltip enterDelay={0} title={tooltips[header] || 'Sort'}>
                                                    <InfoOutlined className={classes.info} />
                                                </Tooltip>
                                            )}
                                        </TableSortLabel>
                                    )}

                                    {header && !onSort && (
                                        <>
                                            {header}
                                            {tooltips[header] && (
                                                <Tooltip enterDelay={0} title={tooltips[header] || 'Sort'}>
                                                    <InfoOutlined className={classes.info} />
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rindex) => {
                            const navigate = row.navigate || {};

                            return (
                                <React.Fragment key={`row-${rindex}`}>
                                    <TableRow
                                        key={`row-${rindex}`}
                                        {...navigate}
                                        component={row.navigate && Link}
                                        hover={!!row.navigate}
                                        sx={
                                            row.navigate && {
                                                cursor: 'pointer',
                                            }
                                        }
                                    >
                                        {row.columns.map((column, cindex) => {
                                            return (
                                                <TableCell
                                                    key={`${rindex}-col-${cindex}`}
                                                    classes={{
                                                        body: classes.body,
                                                        head: classes.head,
                                                    }}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {column}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
                {pagination && (
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={perPageOptions}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            root: classes.paginationRoot,
                        }}
                    />
                )}
            </TableContainer>
        </>
    );
}
