import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { phone } from 'phone';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
// Components
import Lottie from 'lottie-react';
import SuccessAnimation from 'assets/animations/party-success.json';
import { Box, Checkbox, Container, FormControlLabel, Grid, Paper, TextField, Typography, useTheme } from '@mui/material';
import { Fade } from 'react-reveal';
import { LoadingButton } from '@mui/lab';
import LogoFullWhite from 'assets/images/logos/logo-full-white.png';

// styles
import useStyles from './styles';

export default function Home() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState(1);
    const [form, setForm] = useState(1);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { referrer_code } = queryString.parse(search);
    const theme = useTheme();

    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
    } = useForm();

    const emailWatch = watch('email', '');
    const customCodeWatch = watch('useCustomCode', '');

    async function presubmit() {
        setSubmitting(true);
        await handleSubmit(handlePartner)();
        setSubmitting(false);
    }

    async function handlePartner(data) {
        setSubmitting(true);
        try {
            const { terms, ...rest } = data;

            const email = data.email.toLowerCase();
            const params = {
                email,
                type: 'ReferralPartner',
                referrerCode: referrer_code,
                ...rest,
            };

            await axios.post(`${ApiResource.endpoint}/v2/users/signup`, params);

            await axios.get(`${ApiResource.endpoint}/v2/authentication/${email}?to=referral-partner&template=SignupRp`, {
                ...data,
                redirect: location.state?.redirect,
            });
            setState(2);
        } catch (error) {
            console.log(error);
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    async function processSignupResend(data) {
        setSubmitting(true);
        try {
            await axios.get(`${ApiResource.endpoint}/v2/authentication/${data.email}?to=referral-partner`, {
                ...data,
                redirect: location.state?.redirect,
            });
            setState(2);
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
        }
        setSubmitting(false);
    }

    async function processLogin(data) {
        setSubmitting(true);
        try {
            await axios.get(`${ApiResource.endpoint}/v2/authentication/${data.email}?to=referral-partner`, {
                ...data,
                redirect: location.state?.redirect,
            });
            setState(2);
        } catch (error) {
            console.log(error);
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
        }
        setSubmitting(false);
    }

    return (
        <Box className={classes.content}>
            <Container maxWidth={'xl'}>
                <Grid container spacing={4} className={classes.heroGrid}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                    >
                        <Box sx={{ maxWidth: '708px' }}>
                            <img src={LogoFullWhite} className={classes.fullLogo} />
                            <Typography variant="h1s" sx={{ fontWeight: '700' }}>
                                Access your Partner Portal
                            </Typography>
                            <Typography variant="h5">Log in to get access to your Referral Partner Back Office.</Typography>
                            <Typography variant="h5" sx={{ marginTop: 3, fontWeight: '600' }}>
                                The Back Office portal has:
                            </Typography>
                            <Box className={classes.list} sx={{ marginTop: 4 }}>
                                <Box className={classes.listItem}>
                                    <Box className={classes.listDot} />
                                    <Typography color="yellow.main" variant="h5" sx={{ textTransform: 'uppercase' }}>
                                        Deal Register
                                    </Typography>
                                </Box>
                                <Box className={classes.listItem}>
                                    <Box className={classes.listDot} />
                                    <Typography color="yellow.main" variant="h5" sx={{ textTransform: 'uppercase' }}>
                                        Partner Resources
                                    </Typography>
                                </Box>
                                <Box className={classes.listItem}>
                                    <Box className={classes.listDot} />
                                    <Typography color="yellow.main" variant="h5" sx={{ textTransform: 'uppercase' }}>
                                        Custom Referral Link
                                    </Typography>
                                </Box>
                                <Box className={classes.listItem}>
                                    <Box className={classes.listDot} />
                                    <Typography color="yellow.main" variant="h5" sx={{ textTransform: 'uppercase' }}>
                                        Custom Referral Codes
                                    </Typography>
                                </Box>
                                <Box className={classes.listItem}>
                                    <Box className={classes.listDot} />
                                    <Typography color="yellow.main" variant="h5" sx={{ textTransform: 'uppercase' }}>
                                        Sub-RP Invites
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {form === 0 && (
                            <Fade up>
                                <Paper className={classes.form} onSubmit={presubmit} component="form" sx={{ maxWidth: '457px' }}>
                                    <input type="submit" style={{ display: 'none' }} />
                                    {state === 1 && (
                                        <>
                                            <Typography variant="h4" sx={{ fontWeight: '600', marginBottom: 2 }}>
                                                Fill out the form to get started
                                            </Typography>
                                            <Typography variant="font3" sx={{ marginBottom: 5 }}>
                                                Already have an account?{' '}
                                                <Typography
                                                    variant="font3"
                                                    className="link"
                                                    sx={{ display: 'inline-block', cursor: 'pointer' }}
                                                    onClick={() => setForm(1)}
                                                >
                                                    Sign in
                                                </Typography>
                                            </Typography>
                                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email address"
                                                        error={!!errors.email}
                                                        helperText={errors?.email?.message}
                                                        {...register('email', {
                                                            required: 'Invalid Email',
                                                            validate: (value) => validator.isEmail(value),
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="First name"
                                                        error={!!errors.firstName}
                                                        helperText={errors?.firstName?.message}
                                                        {...register('firstName', {
                                                            required: 'Required Field',
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Last name"
                                                        error={!!errors.lastName}
                                                        helperText={errors?.lastName?.message}
                                                        {...register('lastName', {
                                                            required: 'Required Field',
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Phone number"
                                                        error={!!errors.phone}
                                                        helperText={errors?.phone?.message}
                                                        {...register('phone', {
                                                            required: 'Invalid Phone Number',
                                                            validate: (value) => phone(value).isValid,
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box className={classes.customReferralCode}>
                                                        <FormControlLabel
                                                            control={<Checkbox name="useCostomCode" {...register('useCustomCode')} />}
                                                            label={
                                                                <Typography variant="font4" sx={{ fontWeight: '400', color: '#84818A' }}>
                                                                    Customize your referral code (optional)
                                                                </Typography>
                                                            }
                                                        />
                                                        {customCodeWatch && (
                                                            <TextField
                                                                className={classes.field}
                                                                fullWidth
                                                                inputProps={{
                                                                    className: classes.input,
                                                                }}
                                                                label="Custom Referral Code"
                                                                error={!!errors.customCode}
                                                                helperText={errors?.customCode?.message}
                                                                {...register('customCode', {
                                                                    required: true,
                                                                    maxLength: {
                                                                        value: 15,
                                                                        message: 'Value must be less then 15 characters',
                                                                    },
                                                                    validate: async (value) => {
                                                                        try {
                                                                            const response = await axios.get(
                                                                                `${ApiResource.endpoint}/v2/users/referral-code-used/${value}`,
                                                                            );
                                                                            if (response.data.taken) return 'Referral code already in use.';

                                                                            return true;
                                                                        } catch (error) {
                                                                            return 'Error fetching custom code';
                                                                        }
                                                                    },
                                                                })}
                                                            />
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <LoadingButton
                                                variant="contained"
                                                onClick={presubmit}
                                                sx={{ borderRadius: 0, marginBottom: 3 }}
                                                size="large"
                                                fullWidth
                                                loading={submitting}
                                            >
                                                Sign up
                                            </LoadingButton>
                                            <FormControlLabel
                                                sx={{ marginBottom: 8 }}
                                                control={<Checkbox name="q22021" defaultChecked={true} {...register('terms')} />}
                                                label={
                                                    <Typography variant="font4" sx={{ fontWeight: '400', color: '#84818A' }}>
                                                        By clicking Create account, I agree that I have read and accepted the Terms of Use
                                                        and Privacy Policy
                                                    </Typography>
                                                }
                                            />
                                            <Typography variant="font4" sx={{ fontWeight: '400' }}>
                                                Protected by reCAPTCHA and subject to the{' '}
                                                <a href="https://thereliefconsultants.com/privacy" target="_blank" className="link">
                                                    The Relief Consultants Privacy Policy
                                                </a>{' '}
                                                and{' '}
                                                <a href="https://thereliefconsultants.com/terms" target="_blank" className="link">
                                                    Terms of Service.
                                                </a>
                                            </Typography>
                                        </>
                                    )}

                                    {state === 2 && (
                                        <Fade up>
                                            <Box className={classes.animationContainer}>
                                                <Lottie loop={false} animationData={SuccessAnimation} className={classes.animation} />
                                                <Typography sx={{ marginTop: 4, marginBottom: 1, fontWeight: '700' }} variant="h4">
                                                    You have signed up successfully!
                                                </Typography>
                                                <Typography sx={{ marginBottom: 4, maxWidth: '400px' }} variant="font1">
                                                    An email containing a futher instructions was sent to {emailWatch}.
                                                </Typography>
                                                <LoadingButton loading={submitting} onClick={handleSubmit(processSignupResend)}>
                                                    Didn't receive a link? Click here to resend.
                                                </LoadingButton>
                                            </Box>
                                        </Fade>
                                    )}
                                </Paper>
                            </Fade>
                        )}

                        {form === 1 && (
                            <Fade right>
                                <Paper
                                    className={classes.form}
                                    component="form"
                                    onSubmit={handleSubmit(processLogin)}
                                    sx={{ maxWidth: '457px' }}
                                >
                                    <input type="submit" style={{ display: 'none' }} />
                                    {state === 1 && (
                                        <>
                                            <Typography variant="h4" sx={{ fontWeight: '600', marginBottom: 2 }}>
                                                Use your email to login
                                            </Typography>
                                            <Typography variant="font3" sx={{ marginBottom: 3 }}>
                                                Don't have an account?{' '}
                                                <Typography
                                                    variant="font3"
                                                    className="link"
                                                    sx={{ display: 'inline-block', cursor: 'pointer' }}
                                                    onClick={() => setForm(0)}
                                                >
                                                    Sign up
                                                </Typography>
                                            </Typography>

                                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email address"
                                                        error={!!errors.email}
                                                        helperText={errors?.email?.message}
                                                        {...register('email', {
                                                            required: 'Invalid Email',
                                                            validate: (value) => validator.isEmail(value),
                                                        })}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <LoadingButton
                                                onClick={handleSubmit(processLogin)}
                                                variant="contained"
                                                sx={{ borderRadius: 0, marginBottom: 3 }}
                                                size="large"
                                                fullWidth
                                                loading={submitting}
                                            >
                                                Send Login Link
                                            </LoadingButton>
                                            <FormControlLabel
                                                sx={{ marginBottom: 8 }}
                                                control={<Checkbox name="q22021" defaultChecked={true} {...register('terms')} />}
                                                label={
                                                    <Typography variant="font4" sx={{ fontWeight: '400', color: '#84818A' }}>
                                                        By logging into my account, I agree that I have read and accepted the Terms of Use
                                                        and Privacy Policy
                                                    </Typography>
                                                }
                                            />
                                            <Typography variant="font4" sx={{ fontWeight: '400' }}>
                                                Protected by reCAPTCHA and subject to the{' '}
                                                <a href="https://thereliefconsultants.com/privacy" target="_blank" className="link">
                                                    The Relief Consultants Privacy Policy
                                                </a>{' '}
                                                and{' '}
                                                <a href="https://thereliefconsultants.com/terms" target="_blank" className="link">
                                                    Terms of Service.
                                                </a>
                                            </Typography>
                                        </>
                                    )}
                                    {state === 2 && (
                                        <Fade up>
                                            <Box className={classes.animationContainer}>
                                                <Lottie loop={false} animationData={SuccessAnimation} className={classes.animation} />
                                                <Typography sx={{ marginTop: 4, marginBottom: 1, fontWeight: '700' }} variant="h4">
                                                    Log in link sent!
                                                </Typography>
                                                <Typography sx={{ marginBottom: 4, maxWidth: '400px' }} variant="font1">
                                                    An email containing a login link was sent to your {emailWatch}.
                                                </Typography>
                                                <LoadingButton loading={submitting} onClick={handleSubmit(processLogin)}>
                                                    Didn't receive a link? Click here to resend.
                                                </LoadingButton>
                                            </Box>
                                        </Fade>
                                    )}
                                </Paper>
                            </Fade>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
