// UI
import { Search } from '@mui/icons-material';
import { Box, Button, Container, InputAdornment, MenuItem, Paper, Skeleton, TablePagination, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import DownStreamRedux from 'redux/actions/downstream';
import DownstreamItem from './DownstreamItem/DownstreamItem';
import { LoadingButton } from '@mui/lab';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Downstream() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const dispatch = useDispatch();
    const { downstream, user } = useSelector(({ downstream, user }) => ({ downstream, user }));
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'applicationCount',
    });

    useEffect(() => {
        getDownstream();
    }, [sort, page, perPage]);

    async function handleDownload() {
        setDownloading(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/partners/downstream/download?type=downstream`, {
                headers: { 'x-auth': user.authToken },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `downstream.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setDownloading(false);
    }

    async function getDownstream() {
        setLoading(true);
        try {
            await dispatch(
                DownStreamRedux.get({
                    perPage,
                    page,
                    order: sort.order,
                    orderBy: sort.orderBy,
                    search,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Paper className={classes.paper}>
                    <Box className={classes.tableActions}>
                        <Box className={classes.tableAction}>
                            <LoadingButton onClick={handleDownload} loading={downloading} variant="contained">
                                Download
                            </LoadingButton>
                        </Box>
                        <Box className={classes.tableAction}>
                            <TextField
                                select
                                size="small"
                                value={sort.orderBy}
                                onChange={(e) =>
                                    setSort({
                                        order: 'DESC',
                                        orderBy: e.target.value,
                                    })
                                }
                                label="Sort By"
                            >
                                <MenuItem value={'applicationCount'}>Applications</MenuItem>
                                <MenuItem value={'leadCount'}>Leads</MenuItem>
                                <MenuItem value={'creditInProgress'}>$ Credits in Progress</MenuItem>
                                <MenuItem value={'creditAwaiting'}>$ Credit Awaiting</MenuItem>
                                <MenuItem value={'creditSubmitted'}>$ Credits Submitted</MenuItem>
                                <MenuItem value={'creditReceived'}>$ Credits Received</MenuItem>
                            </TextField>
                        </Box>
                        <Box className={classes.tableAction}>
                            <TextField
                                size="small"
                                placeholder="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getDownstream();
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button sx={{ textTransform: 'none' }} onClick={getDownstream}>
                                                Search
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    {!loading && downstream.count === 0 && (
                        <Box className={classes.emptyTable}>
                            <Typography variant="font4">You have no down stream yet. Refer some partners!</Typography>
                        </Box>
                    )}
                </Paper>

                <TablePagination
                    component="div"
                    count={downstream.count}
                    page={page}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[1, 5, 10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRows}
                />

                <Box className={classes.downstreams}>
                    {loading &&
                        Array.from(new Array(3)).map((_, i) => {
                            return <Skeleton key={i} variant="rectangular" width="100%" height="60px" />;
                        })}
                    {!loading &&
                        downstream.items?.map?.((partner, i) => {
                            return <DownstreamItem partner={partner} key={i} />;
                        })}
                </Box>

                <TablePagination
                    component="div"
                    count={downstream.count}
                    page={page}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[1, 5, 10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRows}
                />
            </Container>
        </Box>
    );
}
