// UI
import { Box, Button, InputAdornment, MenuItem, Paper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import FormRedux from 'redux/actions/forms';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { STATUS_TO_READABLE, VISA_CONTRACT_STATUS } from 'utils/constants';
import { Check, Close, Search, Warning } from '@mui/icons-material';
import NotesDialog from 'components/Dialogs/NotesDialog/NotesDialog';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

const TableHeaders = ['Name', 'Status', 'Referred By', 'Credit', 'Processing Fee', 'Last Updated', 'Started On', ''];
// const TableHeadersVisa = ['Name', 'Status', 'Referred By', 'Credit', 'Last Updated', 'Started On', ''];
const translation = {
    Name: 'firstName',
    Status: 'status',
    'Referred By': 'ReferralPartnerId',
    Credit: 'Credit.total',
    'Processing Fee': 'metadata.processingFeePaid',
    'Last Updated': 'updatedAt',
    'Started On': 'createdAt',
};

export default function Applications({ type }) {
    const classes = useStyles();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [search, setSearch] = useState(query.search || '');
    const [notes, setNotes] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));
    const [forms, setForms] = useState({ items: [], count: 0 });
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Started On',
    });

    useEffect(() => {
        getForms();
    }, [sort, page, perPage, status]);

    async function getForms() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];

            let url = `/forms?perPage=${perPage}&page=${page}&order=${sort.order}&orderBy=${orderByTl}&search=${search}&status=${status}`;
            if (type) url += `&type=${type}`;

            const response = await axios.get(`${ApiResource.endpoint}/partners${url}`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });
            setForms(response.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    function handleViewNotes(item) {
        setNotes(item);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Paper className={classes.paper}>
            <NotesDialog type="Form" item={notes} open={Boolean(notes)} onClose={() => setNotes(false)} />
            <Box className={classes.tableActions}>
                {/* <Typography variant="h5" sx={{ fontWeight: '600', marginBottom: 1 }}></Typography> */}
                <TextField
                    size="small"
                    placeholder="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            getForms();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button sx={{ textTransform: 'none' }} onClick={getForms}>
                                    Search
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    select
                    size="small"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Search by Status"
                    sx={{ minWidth: '200px' }}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value={''}>All</MenuItem>
                    {type !== 'visa-settlement' &&
                        Object.keys(STATUS_TO_READABLE).map((key) => {
                            return <MenuItem value={key}>{STATUS_TO_READABLE[key]}</MenuItem>;
                        })}
                    {type === 'visa-settlement' &&
                        VISA_CONTRACT_STATUS.map((status) => {
                            return <MenuItem value={status}>{status}</MenuItem>;
                        })}
                </TextField>
            </Box>

            <CustomTable
                classes={{ tableHeaderCell: classes.tableHeaderCell }}
                count={forms.count}
                headers={TableHeaders}
                onSort={handleOnSort}
                rowsPerPage={perPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={3}
                pagination={forms.count !== 0}
                rows={
                    loading
                        ? Array.from(new Array(5)).map((_, i) => {
                              return {
                                  columns: [
                                      <Skeleton />,
                                      <Skeleton />,
                                      <Skeleton />,
                                      <Skeleton />,
                                      <Skeleton />,
                                      <Skeleton />,
                                      <Skeleton />,
                                  ],
                              };
                          })
                        : forms.items.map((item) => {
                              if (item.type === 'ERC') {
                                  return {
                                      navigate: { to: `/form/${item.id}`, state: { form: item } },
                                      columns: [
                                          <Typography
                                              variant="font2"
                                              color={item.metadata?.alternateCommissionRate ? 'error.main' : null}
                                              sx={{ fontWeight: '500' }}
                                          >
                                              {item.firstName} {item.lastName}
                                          </Typography>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {item.status}
                                          </Typography>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {item.ReferralPartner?.User?.name}
                                          </Typography>,
                                          <Typography
                                              variant="font3"
                                              color={!item.Credit?.total ? '#000' : 'success.main'}
                                              sx={{ fontWeight: '600' }}
                                          >
                                              <span className="center">
                                                  {['Not Interested', 'Ineligible'].includes(item.status) ? (
                                                      <span className="red">-</span>
                                                  ) : item.Credit?.total ? (
                                                      `$${item.Credit?.total?.numberWithCommas(2) || ''}`
                                                  ) : (
                                                      'Docs Needed'
                                                  )}
                                              </span>
                                          </Typography>,
                                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                              {item.metadata.processingFeePaid ? <Check color="success" /> : <Close color="error" />}
                                          </Box>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.createdAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Button
                                              disabled={item.notesCount === 0}
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleViewNotes(item);
                                              }}
                                          >
                                              View Notes
                                          </Button>,
                                      ],
                                  };
                              }
                              if (item.type === 'self-employment') {
                                  return {
                                      navigate: { to: `/form/${item.id}`, state: { form: item } },
                                      columns: [
                                          <Typography
                                              variant="font2"
                                              color={item.metadata?.alternateCommissionRate ? 'error.main' : null}
                                              sx={{ fontWeight: '500' }}
                                          >
                                              {item.firstName} {item.lastName}
                                          </Typography>,
                                          item?.metadata?.alternateCommissionRate ? (
                                              <Tooltip title="Lower Commission rate due to IRS related issue">
                                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
                                                      <Warning color="error" sx={{ fontSize: 19 }} />
                                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                          {STATUS_TO_READABLE[item.status]}
                                                      </Typography>
                                                  </Box>
                                              </Tooltip>
                                          ) : (
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {STATUS_TO_READABLE[item.status]}
                                              </Typography>
                                          ),
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {item.ReferralPartner?.User?.name}
                                          </Typography>,
                                          <Typography
                                              variant="font3"
                                              color={!item.Credit?.total ? '#000' : 'success.main'}
                                              sx={{ fontWeight: '600' }}
                                          >
                                              <span className="center">
                                                  {['Not Interested', 'Ineligible'].includes(item.status) ? (
                                                      <span className="red">-</span>
                                                  ) : item.Credit?.total ? (
                                                      `$${item.Credit?.total?.numberWithCommas(2) || ''}`
                                                  ) : (
                                                      'Docs Needed'
                                                  )}
                                              </span>
                                          </Typography>,
                                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                              {item.metadata.processingFeePaid ? <Check color="success" /> : <Close color="error" />}
                                          </Box>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.createdAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Button
                                              disabled={item.notesCount === 0}
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleViewNotes(item);
                                              }}
                                          >
                                              View Notes
                                          </Button>,
                                      ],
                                  };
                              }
                              if (item.type === 'visa-settlement') {
                                  return {
                                      navigate: { to: `/form/${item.id}`, state: { form: item } },
                                      columns: [
                                          <Typography
                                              variant="font2"
                                              color={item.metadata?.alternateCommissionRate ? 'error.main' : null}
                                              sx={{ fontWeight: '500' }}
                                          >
                                              {item.firstName} {item.lastName}
                                          </Typography>,
                                          item?.metadata?.alternateCommissionRate ? (
                                              <Tooltip title="Lower Commission rate due to IRS related issue">
                                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
                                                      <Warning color="error" sx={{ fontSize: 19 }} />
                                                      <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                          {item.status}
                                                      </Typography>
                                                  </Box>
                                              </Tooltip>
                                          ) : (
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {item.status}
                                              </Typography>
                                          ),
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {item.ReferralPartner?.User?.name}
                                          </Typography>,
                                          <Typography
                                              variant="font3"
                                              color={!item.Credit?.total ? '#000' : 'success.main'}
                                              sx={{ fontWeight: '600' }}
                                          >
                                              <span className="center">
                                                  {['Not Interested', 'Ineligible'].includes(item.status) ? (
                                                      <span className="red">-</span>
                                                  ) : item.Credit?.total ? (
                                                      `$${item.Credit?.total?.numberWithCommas(2) || ''}`
                                                  ) : (
                                                      '-'
                                                  )}
                                              </span>
                                          </Typography>,
                                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                              {item.metadata.processingFeePaid ? <Check color="success" /> : <Close color="error" />}
                                          </Box>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                              {dayjs(item.createdAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                          <Button
                                              disabled={item.notesCount === 0}
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleViewNotes(item);
                                              }}
                                          >
                                              View Notes
                                          </Button>,
                                      ],
                                  };
                              }
                          })
                }
            />
            {!loading && forms.count === 0 && (
                <Box className={classes.emptyTable}>
                    <Typography variant="font4">You have no Deals in Progress.</Typography>
                </Box>
            )}
        </Paper>
    );
}
